import medicine from './medicine.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={medicine} className="App-logo" alt="medicine" />
        <a
          className="App-link"
          href="https://www.medicosbrasil.com/profissional/victor-depoian-bucceroni"
          target="_blank"
          rel="noopener noreferrer"
        >
        <h1>
          Dr. Victor Depoian Bucceroni
        </h1>
        </a>
        <iframe src="https://www.youtube.com/embed/tPX3atc0uac"></iframe>

  

      </header>
    </div>
  );
}

export default App;

       